
p {
  color: white;
}
.App {
  width: 100vw;
  height: 100vh;
}

.MobileLayout, .DesktopLayout {
  display: flex;
  flex-direction: column;
  background-color: #151327;
  height: 100%;
}

#app-navbar-container {
  width: 100%;
  height: 55px;
  background-color:#0F0E1E;
  flex-shrink: 0;
  color: white;
  display: flex;
  align-items: center;
}

#app-body-container {
  height: calc(100% - 55px - 20px * 2);
  width: calc(100% - 20px * 2);
  padding: 20px;
  display: flex;
  flex-grow: none;
}

#app-stream-viewer-page-section {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#app-sidebar-page-section {
  width: calc(30% - 40px);
  min-width: 340px;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#app-sidebar-container {
  width: calc(100% - 2px * 2);
  height: 100%;
  border: 2px solid #242141;
  border-radius: 6px;
}

#auth-container {
  width: calc(100% - 20px * 2);
  border-radius: 6px;
  padding: 20px;
  background-color: #242141;
}


#change-this-to-component-stream {
  width: 100%;
  height: 80%;
  background-color: black;
}

#change-this-to-component-stream-selector {
  width: calc(100% - 2px * 2);
  height: 15%;
  margin-top: 10px;
  border: 2px solid #242141;
}

/* Chat --------------------------------------------------------------------- */

#chat-room-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#messages-scroll-container {
  width: 100%;
  flex-grow: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

#chat-input-section-container {
  height: calc(100px - 20px);
  width: calc(100% - 10px * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

#chat-text-input {
  flex-grow: 1;
  height: 40px;
}

.message {
  margin: 10px 0px;
}

.message-by-logged-in-user {
  padding: 20px;
  border: 2px solid blue;
}

.message-by-other-user {
  padding: 20px;
  border: 2px solid red;
}

.MobileLayout #app-body-container {
  flex-direction: column;
  padding: 0px;
  width: 100%;
  height: calc(100% - 55px);
}

.MobileLayout #app-stream-viewer-page-section {
  width: 100%;
  height: 500px;
}

.MobileLayout #app-sidebar-page-section {
  width: 100%;
  min-width: 0px;
  padding: 0px;
}

iframe {
  border: none;
}

#app-sidebar-tabs {
  width: 100%;
  color: white;
  display: flex;
  box-sizing: border-box;
}
#app-sidebar-tabs span {
  cursor: pointer;
  padding: 10px;
}
#app-sidebar-tabs span:hover {
  background-color: #343151;
}
#app-sidebar-tabs .selected {
  background-color: #242141;
}

.MobileLayout #auth-container {
  flex: 1;
}

.MobileLayout #app-sidebar-container {
  display: flex;
}

#app-navbar-tabs span {
  padding: 10px;
  cursor: pointer;
}

#app-navbar-tabs span.selected {
  background-color: #242141;
}
#app-navbar-tabs span:hover {
  background-color: #343151;
}
